.login-container {
  /* position: relative; */
  --background: url('/public/assets/cloud_bg.jpg') center/cover no-repeat;
  /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */

  /* min-height: 100vh;
    width: 100vw;
    transition: opacity 0.75s ease-in-out;
    transition-delay: 100ms;
    display: flex;
    justify-content: center;
    align-items: center; */
}