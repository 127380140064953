/* ion-button {
    --border-radius: 0;
    --border: 3px solid var(--ion-color-dark);
    box-shadow: rgb(29, 43, 42) 0px 0.1rem 0px 0px inset, rgb(29, 43, 42) 0px -0.4rem 0px 0px inset, rgb(29, 43, 42) -0.4rem 0px 0px 0px inset, rgb(29, 43, 42) 0.1rem 0px 0px 0px inset;
} */

@font-face {
  font-family: 'Cormorant';
  src: url('/public/assets/fonts/Cormorant-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  /* You can add more font formats (e.g., woff, ttf) and font weights/styles here */
}

* {
  font-family: 'Cormorant';
}

ion-label {
  font-family: 'Cormorant' !important;
}


ion-item {
    --background: inherit;
}

ion-list-header {
    --background: inherit;
}

/* ion-card {
    border-radius: 0;
    border: 2px solid var(--ion-color-primary);
    box-shadow: none;
} */

.double-border-button {
    --border-radius: 0;
    background: none;
    display: inline-block;
    width: 98%;
    /* margin: 0 auto; */
    /* padding: 10px 20px; */
    background-color: transparent;
    color: #FFF8D9;
    position: relative; /* Needed for positioning pseudo-elements */
    border: none; /* Remove default button border */
    backdrop-filter: blur(10px);
    /* Main Border */
    border: 1px solid #FFF8D9;

    @media screen and (min-width: 768px) {
      max-width: 300px;
    }

  }
  
  .double-border-button::before,
  .double-border-button::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 5px; /* Adjust this value to control vertical offset */
    left: 5px; /* Adjust this value to control horizontal offset */
    right: -7px; /* Adjust this value to control horizontal offset */
    bottom: -7px; /* Adjust this value to control vertical offset */
    border: 1px solid #FFF8D9; /* Color and style of the second border */
  }

  .double-border-button-dark {
    --border-radius: 0;
    background: none;
    display: inline-block;
    width: 98%;
    /* margin: 0 auto; */
    /* padding: 10px 20px; */
    background-color: transparent;
    color: var(--ion-color-dark);
    position: relative; /* Needed for positioning pseudo-elements */
    border: none; /* Remove default button border */
    backdrop-filter: blur(10px);
    /* Main Border */
    border: 1px solid var(--ion-color-dark);

    @media screen and (min-width: 768px) {
      max-width: 300px;
    }

  }
  
  .double-border-button-dark::before,
  .double-border-button-dark::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 5px; /* Adjust this value to control vertical offset */
    left: 5px; /* Adjust this value to control horizontal offset */
    right: -7px; /* Adjust this value to control horizontal offset */
    bottom: -7px; /* Adjust this value to control vertical offset */
    border: 1px solid var(--ion-color-dark); /* Color and style of the second border */
  }



  .clouds-bg {
    --background: url('/public/assets/cloud_bg.jpg') center/cover no-repeat;
  }

  .flowers-bg {
    --background: url('/public/assets/flowers.png') center/cover no-repeat;
    background: url('/public/assets/flowers.png') center/cover no-repeat;
  }

  .flowers-bg {
    ion-item {
      --background: none;
    }
  }

  .transparent-header {
    --ion-background-color: transparent; /* Set the background color to transparent */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box-shadow */
  }

  ion-item.input-item {
    --background: inherit;
    --color: var(--ion-color-tertiary);
  
    --border-color: var(--ion-color-tertiary-shade);
    --border-style: solid;
    --border-width: 3px;
  
    --border-radius: 0;
    /* --detail-icon-color: white;
    --detail-icon-opacity: 1;
    --detail-icon-font-size: 20px; */
    --padding-start: 5px;
    caret-color: #fff;

    @media (prefers-color-scheme: dark) {
          --color: var(--ion-color-dark);
      }
  }