/* styles.css */
.welcome-container {
    display: flex;
    height: 100vh;
    padding: 16px;
  }
  
  .welcome-content-container {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center; /* Center vertically */
    flex-direction: column; /* Stack children vertically */
  }
  
  .welcome-logo {
    width: 100%;
    max-width: 300px;
    margin: 100px auto 0 auto;
    display: block;
  }
  
  .welcome-button {
    position: absolute;
    bottom: 100px;
  }
  
  .welcome-divider-bottom {
    position: absolute;
    bottom: 20px;
  }
  