.birthchart-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    min-height: 100vh;
    padding-top: 150px;

    @media (max-width: 768px) {
        padding-top: 150px;
    }  
}

.birthchart-close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px !important;
    z-index: 9999;
    :is(ion-icon) {
        font-size: 30px !important;
    }
}

ion-card {
    border-radius: 0;
    box-shadow: none;
}

ion-card-content {
    margin-top: 10px;
    white-space: pre-wrap;
    max-height: 80vh;
    overflow: scroll;
}

ion-card.input-card {
    overflow: visible;
    max-width: 600px;
    position: relative;
    border: 3px solid var(--ion-color-tertiary-shade);
    /* color: var(--ion-color-tertiary) */
    
    @media (max-width: 768px) {
        max-width: 95vw;
    }  
}

ion-card-header {
    border-bottom: 3px solid var(--ion-color-tertiary-shade);
}

ion-item {
    --background: inherit;
    --padding-start: 0;
    --border-color: var(--ion-color-tertiary-shade);
}

.page-container {
    position: relative;
    background-image: url('/public/assets/cloud_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    width: 100vw;
    overflow: scroll;
    transition: opacity 0.75s ease-in-out;
    transition-delay: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
  }



  ion-button.boxed {
    margin-top: 10px;
    --border-radius: 0;
    /* --border-color: #000;
    --border-style: solid;
    --border-width: 1px; */

    --box-shadow: 4px 4px 0 0 var(--ion-color-dark);
  }

  ion-button.dark-bg {
    --box-shadow: 4px 4px 0 0 var(--ion-color-tertiary);
  }

  ion-button.light-bg {
    --box-shadow: 4px 4px 0 0 var(--ion-color-light);
  }
  

.wider-card {
    min-width: 400px;

    @media (max-width: 768px) {
        min-width: auto;
    }
}

.fade-cover {
    overflow: hidden
}

.fade-cover::before {
    content: ""; /* Create an empty pseudo-element */
    position: absolute; /* Position it absolutely */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 1) 100%); /* Adjust the color stops */

    z-index: 1; /* Adjust the stacking order if needed */
    pointer-events: none; /* Allow clicks to pass through */
  }